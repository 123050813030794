import * as React from "react"
import { useEffect } from "react"
import HomeHero from "../components/home/hero"
import Navbar from "../components/global/navbar"
import Footer from "../components/global/Footer"
import Helmet from "react-helmet"

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Cotizar y rastrear</title>
      </Helmet>
      <div className="flex flex-col min-h-screen justify-center w-full">
        <Navbar />
        <div className="flex-grow bg-white max-w-7xl px-6 justify-center self-center w-full">
          <h1 className="base-title text-primary-dark-green">Cotiza y rastrea</h1>
          <HomeHero />
        </div>
        <Footer />
      </div>
    </>
  )
}

export default IndexPage
